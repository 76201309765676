@import '../colors';

.css-1rhbuit-multiValue {
  background: $primary;
  color: $color-text-main;
}

.select__multi-value .select__multi-value__label {
  color: $color-text-main;
}
