.auth-wrapper {
  .auth-input {
    max-width: 45px;
    padding-right: 0.471rem;
    padding-left: 0.471rem;
    @media (max-width: 360px) {
      max-width: 40px;
      max-height: 40px;
    }
  }
}

body,
#root,
.wrapper {
  background:white;
}
