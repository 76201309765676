@import 'src/assets/scss/mixins';

// Accessibility icon sizes
body {
  #INDWrap {
    #INDmenu-btn {
      @include mobile-media {
        width: 60px !important;
        height: 45px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 20px !important;
        }
      }
    }
  }
}
