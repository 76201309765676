@import './src/assets/scss/variables/colors';

.main-menu-content .navigation-main .nav-item.active a span {
  color: $color-text-main;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li.active svg {
  color: $color-text-main;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li a i:before {
  color: unset;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu:not(.expanded)
  .navigation
  li.active
  a {
  background: $primary;
}

.card-component-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  padding: 0;
  flex: 1;
}

.event-card-buttons {
  display: flex;
}
.buttons-event {
  width: 100%;
}
