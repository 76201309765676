$color-text-main: #444b4d;
$color-text-second: rgba(68, 75, 77, 0.3);
$color-logout: #e14069;
$color-logout-light: rgba(225, 64, 105, 0.24);
$grey-light: #f8f8f8;
$color-border-digit: #d8d6de;
$color-square: #d8d8d8;

$color-square-time: #eeeeee;
$color-square-time-title: #b9b9c3;

$color-icon: #4b4b4b;
$color-icon-light: rgba(29.41, 29.41, 29.41, 0.05);
$color-background-card: rgba(186, 191, 199, 0.24);
$color-line: rgba(0, 0, 0, 0.12);

$color-border: #ebe9f1;
$color-success: green;

$primary: #35e9b9;
$primary-light: rgba(53, 233, 185, 0.24);
$secondary: #35e9b9;
$color-placeholder-text: #b9b9c3;

$color-yellow-text: rgb(178 145 37);
$color-yellow-background: #f1db97;
