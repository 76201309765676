@import '../colors';

.modern-nav-toggle {
  .text-primary {
    color: $color-text-main !important;
  }

  svg {
    width: 26px;
    height: 26px;
    viewBox: 0 0 26 27;
    version: 1.1;
    xmlns: "http://www.w3.org/2000/svg";
  }
}

svg {
  width: 26px;
  height: 26px;
  viewBox: 0 0 26 27;
  version: 1.1;
  xmlns: "http://www.w3.org/2000/svg";
}