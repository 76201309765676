@import '../colors';

.card-content-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 12px;
  border-radius: 5px;
  padding: 7px;
  background-color: rgba(53, 233, 185, 0.24);

  i {
    width: 17px;
    height: 17px;
    margin-left: 2px;
  }

  svg,
  i::before {
    color: $primary;
  }
}
