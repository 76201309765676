// Custom scrollbar
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #35e9b9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(20, 91, 72, 0.9);
}
