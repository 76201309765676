[dir='rtl'] .rtl-mirror {
  transform: rotate(180deg);
}

[dir='rtl'] .rtl-mirror-footer {
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

[dir='rtl'] .rtl-mirror-footer-phone {
  @media (max-width: 768px) {
    margin-left: 36px;
  }
}

[dir='rtl'] .card-coming-soon-content-name-rtl {
  display: flex;
  justify-content: flex-end;
}

[dir='rtl'] .invite-guest-button-rtl,
.add-user-button-rtl {
  flex-direction: row-reverse;
}

[dir='rtl'] .phone-input-group-rtl {
  transform: rotate(-180deg);
  transform: rotateY(180deg);

  .phone-button-rtl {
    transform: rotateY(-180deg);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .phone-input-rtl {
    padding-inline-end: 40px;
    transform: rotateY(-180deg);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .phone-input-error-rtl {
    transform: rotate(-180deg);
    transform: rotateY(180deg);
  }
}

[dir='rtl'] .part-a-instructions-titles-rtl {
  span:not(:first-child) {
    margin-left: 12px;
    margin-right: 22px;

    @media (max-width: 768px) {
      margin-left: 6px;
    }
  }
}

[dir='rtl'] .medical-status-on-click-icon-rtl {
  transform: rotate(180deg);
}

html[dir='rtl'] {
  .btn-group {
    .dropdown-menu {
      left: 0 !important;
    }
  }
}

.login-page-intl {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 2;

  ul,
  li {
    list-style-type: none;
  }

  @media (max-width: 968px) {
    right: 0;
  }
}
