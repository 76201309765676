.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu.expanded
  .navbar-header
  .brand-text {
  display: none;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu
  .modern-nav-toggle {
  display: block;
}

.app-content.content.overflow-hidden {
  @media (max-width: 1200px) {
    padding-bottom: 40px;
  }
}

@media (max-width: 1200px) {
  .content-wrapper {
    margin-bottom: 64px !important;
  }
}

.layout-global {
  min-height: 88vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    min-height: 81vh;
  }

  .footer-container {
    margin-top: auto;
  }
}
