@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?xhkpo7');
  src: url('fonts/icomoon.eot?xhkpo7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xhkpo7') format('truetype'),
    url('fonts/icomoon.woff?xhkpo7') format('woff'),
    url('fonts/icomoon.svg?xhkpo7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-color .path1:before {
  content: '\e910';
  color: rgb(66, 133, 244);
}
.icon-google-color .path2:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-google-color .path3:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-google-color .path4:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-facebook-color .path1:before {
  content: '\e924';
  color: rgb(24, 119, 242);
}
.icon-facebook-color .path2:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wrench:before {
  content: '\e900';
  color: #4b4b4b;
}
.icon-wallet:before {
  content: '\e901';
  color: #4b4b4b;
}
.icon-utensils:before {
  content: '\e902';
  color: #4b4b4b;
}
.icon-twitter:before {
  content: '\e903';
  color: #4b4b4b;
}
.icon-sanitizer:before {
  content: '\e904';
  color: #4b4b4b;
}
.icon-meeting-board:before {
  content: '\e905';
  color: #4b4b4b;
}
.icon-linkedin:before {
  content: '\e906';
  color: #4b4b4b;
}
.icon-hard-hat:before {
  content: '\e907';
  color: #4b4b4b;
}
.icon-glass-martini:before {
  content: '\e908';
  color: #4b4b4b;
}
.icon-facebook:before {
  content: '\e909';
  color: #4b4b4b;
}
.icon-envelope-question:before {
  content: '\e90a';
  color: #4b4b4b;
}
.icon-envelope-check:before {
  content: '\e90b';
  color: #4b4b4b;
}
.icon-dumbbell:before {
  content: '\e90c';
  color: #4b4b4b;
}
.icon-building:before {
  content: '\e90d';
  color: #4b4b4b;
}
.icon-bill:before {
  content: '\e90e';
  color: #4b4b4b;
}
.icon-basketball:before {
  content: '\e90f';
  color: #4b4b4b;
}
